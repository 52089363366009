<template>
  <div style="position: relative">
    <el-dialog v-if="dialog" :title="title" class="pdfView" :width="isMobileScreen ? '95vw' : '60vw'"
      :visible.sync="value">
      <div v-if="pdfUrl">
        <div v-if="isMobile">
          <el-button icon="el-icon-refresh" round @click="reload" style="position: absolute; top: 13px; right: 50px"></el-button>
          <iframe id="iframeID" :src="'https://docs.google.com/gview?embedded=true&url=' + pdfUrl" width="100%" height="900" frameborder="0" @load="finishLoad"></iframe>
        </div>
        <iframe v-else :src="pdfUrl" width="100%" height="900" frameborder="0" @load="finishLoad"></iframe>
      </div>
      <div v-if="base64">
        <el-button-group class="pb-4" style="position: absolute; top: 13px; right: 50px">
          <el-button v-if="print" round icon="el-icon-printer" @click="handlePrint"></el-button>
        </el-button-group>
        <vue-pdf-embed ref="pdfRef" class="vue-pdf-embed" :source="base64"></vue-pdf-embed>
      </div>
    </el-dialog>
    <div v-else>
      <div v-if="pdfUrl">
        <div v-if="isMobile">
          <el-button icon="el-icon-refresh" round @click="reload" style="position: absolute; top: 18px; left: 18px"></el-button>
          <iframe id="iframeID" :src="'https://docs.google.com/gview?embedded=true&url=' + pdfUrl" width="100%" height="900" frameborder="0" @load="finishLoad"></iframe>
        </div>
        <iframe v-else :src="pdfUrl" width="100%" height="900" frameborder="0" @load="finishLoad"></iframe>
      </div>
      <div v-if="base64">
        <el-button-group class="pb-4">
          <el-button v-if="print" round icon="el-icon-printer" @click="handlePrint"></el-button>
        </el-button-group>
        <vue-pdf-embed ref="pdfRef" class="vue-pdf-embed" :source="base64"></vue-pdf-embed>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { mapState } from "vuex";
export default {
  components: {
    VuePdfEmbed
  },
  props: {
    base64: String,
    pdfUrl: String || Object,
    pageNum: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      default: false,
    },
    dialog: {
      type: Boolean,
      default: true
    },
    print: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoaded: false,
      isLoading: true,
      pageNumA: 1,
      scale: 1, // 缩放比例
      numPages: 0, // 总页数
      progress: 0,
      total: 1,
      isMobile: false
    };
  },
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  computed: {
    ...mapState(["screenType"]),
    percentageLoading() {
      const percentage = (this.progress / this.total);
      const percentageDisplay = percentage * 100;
      return Math.round(percentageDisplay);
    },
    isMobileScreen() {
      return this.screenType?.isMobileScreen ?? false;
    },
    scaleFun() {
      // 缩放
      var scale = this.scale;
      return `transform:scale(${scale})`;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("set:reload");
        this.isLoaded = false;
        let count = 1
        while (count <= 2) {
          setTimeout(() => {
            this.reload();
          }, 1000 * count);
          count+=1
        }
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    this.detectDevice();

    if (this.pdfUrl) {
      window.addEventListener('resize', this.detectDevice); // Detect changes in window size
      window.addEventListener('orientationchange', this.detectDevice); // Detect changes in orientation
      console.log("mounted:reload")
      this.reload();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectDevice);
    window.removeEventListener('orientationchange', this.detectDevice);
  },
  methods: {
    finishLoad() {
      console.log("finish:loaded")
      setTimeout(() => {
        this.isLoaded = true;
        this.loaded();
      }, 500);
    },
    reload() {
      if (document.getElementById("iframeID")) {
        const tempUrl = document.getElementById("iframeID").src;
        document.getElementById("iframeID").src = "";
        this.$nextTick(() => {
          document.getElementById("iframeID").src = tempUrl;
        })
      }
    },
    detectDevice() {
      // Use userAgent to determine if it's a mobile device
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        userAgent.match(/android/i) ||
        userAgent.match(/webos/i) ||
        userAgent.match(/iphone/i) ||
        userAgent.match(/ipad/i) ||
        userAgent.match(/ipod/i) ||
        userAgent.match(/blackberry/i) ||
        userAgent.match(/windows phone/i)
      ) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    //  console.log(this.isMobile)
    },
    handleProgress(progress) {
      this.isLoading = true;
      this.progress = progress.loaded;
      this.total = progress.total;
    },
    handleLoaded() {
      this.isLoading = false;
      this.progress = 0;
      this.total = 1;
    },
    handlePrint() {
      this.$refs.pdfRef.print();
    },
    loaded() {
      this.$emit("loaded");
    },
    changePage(page) {
      this.pageNumA = page;
      this.$emit("pdfNum", this.pageNumA);
    },
    format(percentage) {
      return percentage === 100 ? 'Rendering.. Please wait' : `Downloading.. ${percentage}%`;
    }
  },
};
</script>

<style >
.pdfView .el-dialog__body {
  height: 80vh;
  overflow-y: scroll;
}

.pdfView .el-dialog {
  margin-top: 5vh !important;
}

.vue-pdf-embed>div {
  margin-bottom: 8px;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
